import { defineMessages, useIntl } from 'react-intl'
import { observer } from 'mobx-react-lite'

import Analytics from 'src/utils/analytics'
import useStore from 'src/stores/useStore'
import { Flex } from 'src/components/UI/Grid/Grid'
import { HeartIcon, HeartIconFilled } from 'src/components/UI/Icons/Icons'

import styles from './FavoriteButton.module.scss'

import type { AinoProduct } from 'src/apollo/types'
import type { CentraProduct } from 'src/types'

type Props = {
  small?: boolean
  product: AinoProduct | null | CentraProduct
}

const messages = defineMessages({
  addToFavorites: {
    defaultMessage: 'Add to favorites',
    id: 'tQ1kyg',
    description: 'Label for button that adds product to favorites',
  },
  removeFromFavorites: {
    defaultMessage: 'Remove from favorites',
    id: 'LDQV2M',
    description: 'Label for button that removes product from favorites',
  },
})

const FavoriteButton = ({ small, product }: Props) => {
  const intl = useIntl()
  const { cart } = useStore()
  let id: any = false
  if (product && product.id) {
    id = product.id
  }

  if (!id) {
    return null
  }

  const isFavorite = cart?.isFavorite(id)

  return (
    <Flex
      className={
        isFavorite ? [styles.button, styles.active].join(' ') : styles.button
      }
      as="button"
      width={small ? 40 : 56}
      height={small ? 40 : 56}
      onClick={(e: any) => {
        e.preventDefault()
        e.stopPropagation()
        if (product) {
          cart.toggleFavorite(product.id)
          const currency = cart.country?.currency
          Analytics.track('add_to_wishlist', {
            currency,
            item_name: product.name,
          })
        }
      }}
      flexShrink={0}
      alignItems="center"
      justifyContent="center"
      aria-label={intl.formatMessage(
        isFavorite ? messages.removeFromFavorites : messages.addToFavorites
      )}
    >
      <Flex alignItems="center" justifyContent="center">
        <HeartIcon width={small ? 20 : 23} height={small ? 17 : 21} />
      </Flex>
      <Flex alignItems="center" justifyContent="center">
        <HeartIconFilled width={small ? 20 : 23} height={small ? 17 : 21} />
      </Flex>
    </Flex>
  )
}

export default observer(FavoriteButton)
