import * as React from 'react'

export type Api = {
  moveToPage: (page: number) => any
  animateToPage: (page: number) => any
}

interface Props {
  className?: string
  pages: number
  onPage?: (page: number) => any
  onApi?: (api: Api) => any
  repeat?: boolean
  decay?: number
  currentPage?: number
  perPage: number
  containerPadding?: number
}

class Thumb extends React.PureComponent<Props> {
  container = React.createRef<HTMLDivElement>()
  wrapper: HTMLElement | null = null
  x = 0
  touchStartX = 0
  touchStartY = 0
  startX = 0
  prevX = 0
  time = Date.now()
  animateTo = 0
  width = 0
  stop = false
  velocity = 0
  preventSwipe = false
  isSwiping = false
  flick = false
  isTouching = false
  perPage = 1
  resizeEvent =
    typeof navigator !== 'undefined' && navigator.userAgent.indexOf('Mobi') > -1
      ? 'orientationchange'
      : 'resize'

  componentDidMount() {
    const { repeat, onApi, decay, onPage } = this.props
    if (this.container && this.container.current) {
      this.wrapper = this.container.current.firstElementChild as HTMLElement
    }
    window.addEventListener(this.resizeEvent, this.onResize)
    window.addEventListener('touchmove', this.preventTouch, { passive: false })
    this.setWidth()
    if (repeat) {
      this.x = this.width
      this.animateTo = this.x
    }
    let loopTime = Date.now()
    let prevPage: any = null
    const loop = () => {
      const now = Date.now()
      if (decay) {
        if (this.velocity && !this.isTouching) {
          const elapsed = now - loopTime
          this.x += this.velocity * elapsed
          // TODO: bounce if not repeated
          this.animateTo = this.x
          this.velocity *= 1 - Math.pow(1 - decay / 10, 2)
          if (this.velocity === Infinity) {
            this.velocity = 0
          }
        }
      } else {
        const animationDistance = this.animateTo - this.x
        if (Math.abs(animationDistance) > 1) {
          const factor = Math.abs(animationDistance) / this.width
          this.x +=
            animationDistance / (this.flick ? 3 + factor * 5 : 5 + factor * 10)
        }
      }
      const page = this.x / this.width
      const pageRounded = Math.round(page)
      if (Math.abs(page - pageRounded) < 0.005) {
        this.x = pageRounded * this.width
      }
      if (decay && onPage) {
        const nextPage = this.getHumanPage(pageRounded)
        if (nextPage !== prevPage) {
          onPage(nextPage)
        }
        prevPage = nextPage
      }
      if (repeat) {
        if (this.x <= 0) {
          this.x = (this.max - 1) * this.width
          this.animateTo = this.x
        } else if (this.x >= this.max * this.width) {
          this.x = this.width
          this.animateTo = this.x
        }
      }
      if (this.wrapper) {
        this.wrapper.style.transform = `translateX(${-this.x}px)`
      }
      loopTime = now
      this.stop || requestAnimationFrame(loop)
    }
    requestAnimationFrame(loop)
    if (onApi) {
      onApi({
        animateToPage: this.animateToPage,
        moveToPage: this.moveToPage,
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener(this.resizeEvent, this.setWidth)
    window.removeEventListener('touchmove', this.preventTouch)
    this.stop = true
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.currentPage !== this.props.currentPage &&
      this.props.currentPage !== undefined
    ) {
      this.animateToPage(this.props.currentPage)
    }
  }

  onResize = () => {
    this.setWidth()
    this.x = Math.round(this.x / this.width) * this.width
    this.animateTo = this.x
  }

  preventTouch = (e: any) => {
    const { decay } = this.props
    if (
      this.container &&
      this.container.current &&
      this.container.current.contains(e.target)
    ) {
      if (
        this.isSwiping ||
        Math.abs(e.touches[0].pageX - this.touchStartX) > 5
      ) {
        this.isSwiping = true
        e.preventDefault()
        e.returnValue = false
        return false
      } else if (Math.abs(e.touches[0].pageY - this.touchStartY) > 10) {
        if (!decay) {
          this.x =
            Math.max(0, Math.min(this.max, Math.round(this.x / this.width))) *
            this.width
          this.animateTo = this.x
        }
        this.preventSwipe = true
        this.isSwiping = false
      }
    }
  }

  setWidth = () => {
    if (this.container && this.container.current) {
      const { width } = this.container.current.getBoundingClientRect()
      this.width = width
    }
  }

  onTouchStart = (e: React.TouchEvent) => {
    this.touchStartX = e.touches[0].pageX
    this.touchStartY = e.touches[0].pageY
    this.preventSwipe = false
    this.startX = this.x
    this.prevX = this.x
    this.velocity = 0
    this.animateTo = this.x
    this.isSwiping = false
    this.isTouching = true
  }

  onTouchMove = (e: React.TouchEvent) => {
    if (this.preventSwipe) {
      return
    }
    const { repeat } = this.props
    const now = Date.now()
    let distance = e.touches[0].pageX - this.touchStartX
    const nextX = this.startX - distance
    const below = nextX < 0
    const above = nextX > this.width * this.max
    this.isTouching = true
    if (below || above) {
      if (repeat) {
        this.x = below ? (this.max - 1) * this.width : this.width
        this.startX = this.x - 11
        this.animateTo = this.startX
        this.touchStartX = e.touches[0].pageX
        this.prevX = this.x
        this.time = now
        requestAnimationFrame(() => {
          this.isSwiping = true
        })
        return
      } else {
        distance /= Math.abs(distance) / this.width + 1.5
      }
    }
    this.x = this.startX - distance
    const nextVelocity =
      Math.floor(((this.x - this.prevX) / (now - this.time)) * 10000) / 10000
    this.velocity = (nextVelocity + this.velocity) / 2
    if (this.velocity === Infinity) {
      this.velocity = 0
    }
    this.animateTo = this.x
    this.prevX = this.x
    this.time = now
  }

  onTouchEnd = () => {
    const { onPage, decay, perPage } = this.props
    this.isTouching = false
    if (this.preventSwipe || decay) {
      return
    }
    const page = this.x / this.width
    let nextPage = Math.max(0, Math.min(this.max, Math.round(page)))
    this.flick = false
    if (Math.abs(this.velocity) > 0.5) {
      this.flick = true
      nextPage =
        this.velocity < 0
          ? Math.max(0, Math.ceil(page) - 1)
          : Math.min(this.max, Math.floor(page) + 1)
    }
    this.animateTo = nextPage * this.width * perPage
    if (onPage) {
      onPage(this.getHumanPage(nextPage))
    }
  }

  animateToPage = (page: number) => {
    const { onPage, perPage, containerPadding } = this.props

    this.flick = true

    if (containerPadding) {
      this.animateTo =
        this.getThumbPage(page) *
        (window.innerWidth - containerPadding) *
        perPage
    } else {
      this.animateTo = this.getThumbPage(page) * this.width * perPage
    }

    if (onPage) {
      onPage(page)
    }
  }

  moveToPage = (page: number) => {
    const { onPage, perPage } = this.props
    this.x = this.getThumbPage(page) * this.width * perPage
    this.animateTo = this.x
    if (onPage) {
      onPage(page)
    }
  }

  getThumbPage = (page: number) => {
    const { repeat, pages } = this.props
    const nextPage = repeat ? page + 1 : page
    return Math.max(
      repeat ? 1 : 0,
      Math.min(nextPage, repeat ? pages : pages - 1)
    )
  }

  getHumanPage = (page: number) => {
    const { repeat } = this.props
    let nextPage = Math.max(0, Math.min(this.max, page))
    if (repeat) {
      nextPage -= 1
      if (nextPage < 0) {
        nextPage = this.max - 2
      } else if (nextPage >= this.max - 1) {
        nextPage = 0
      }
    }
    return nextPage
  }

  get max() {
    const { pages, repeat } = this.props
    return repeat ? pages + 1 : pages - 1
  }

  render() {
    const { className, children } = this.props
    return (
      <div
        className={className}
        ref={this.container}
        onTouchStart={this.onTouchStart}
        onTouchMove={this.onTouchMove}
        onTouchEnd={this.onTouchEnd}
      >
        {children}
      </div>
    )
  }
}

export default Thumb
